import React from 'react';

import { Box, useMediaQuery } from '@mui/material';

import Body from '../../common/body/Body';
import FanGoBody from '../../common/body/FanGoBody';
import { FanGoGridCarousel } from '../../common/carousel';
import CustomGridCarousel from '../../common/carousel/CustomGridCarousel';
import CustomFanGoCard from '../../common/fanGoCard/CustomFanGoCard';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { SubText } from '../../common/texts';

const responsiveSizes = [
  {
    breakpoint: 1200,
    cols: 3,
    rows: 3,
  },
  {
    breakpoint: 975,
    cols: 2,
    rows: 2,
  },
  {
    breakpoint: 650,
    cols: 2,
    rows: 1,
  },
  {
    breakpoint: 500,
    cols: 1,
    rows: 1,
  },
];

const AboutWeddingFeature = ({ data }) => {
  const { weddingSoftwareOptions } = data;
  const { body, featuresList } = weddingSoftwareOptions;

  const cards = [];
  featuresList.forEach((item, index) => {
    const { cardImage, _key, title, text } = item;
    let image;
    let altText;
    if (cardImage) {
      const { asset } = cardImage;
      image = asset?.gatsbyImageData;
      altText = cardImage.altText;
    }

    const card = (
      <CustomFanGoCard
        key={_key}
        title={title}
        image={image}
        altText={altText}
        text={text}
        internalLink={''}
        titleFontWeight="bold"
        minHeight="475px"
      />
    );
    cards.push(card);
  });

  return (
    <FanGoBody mobilePaddingBottom="30px" paddingTop="10px">
      <>
        <div style={{ marginBottom: 10 }}>
          <SubText
            variant="subtitle1"
            marginY={styles.allZero}
            value={<FanGoTextProcessor text={body} textAlign="center" />}
            fontColor={styles.grey}
            textAlignment={styles.alignStyleCCCC}
          />
        </div>
        <CustomGridCarousel
          itemsArray={cards}
          responsiveSizes={responsiveSizes}
          defaultCols={3}
          defaultRows={3}
          paddingBottom={2}
          speed={500}
        />
      </>
    </FanGoBody>
  );
};

export default AboutWeddingFeature;
